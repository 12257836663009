.owl-theme .owl-controls {
    margin-top: 20px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-controls .owl-nav [class*=owl-] {
    color: #fff;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #d6d6d6;
    display: inline-block;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 0px;
}
.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
    background: #869791;
    color: #fff;
    text-decoration: none;
}
.owl-theme .owl-controls .owl-nav .disabled {
    opacity: .5;
    cursor: default;
}
.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #869791;
}


/* preloading images */
.owl-item.loading{
	min-height: 150px;
	background: url(AjaxLoader.gif) no-repeat center center
}

/*EDITED BY DESIGNOVA : Customized Controls*/
.owl-prev{
	position: absolute;
	width: 50px;
	height: 50px;
    background-image: url(../images/prev.png) !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
	top: 50% !important;
    margin-top: -25px !important;
}
.owl-next{
	position: absolute;
	width: 50px;
	height: 50px;
    background-image: url(../images/next.png) !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
	top: 50% !important;
    margin-top: -25px !important;
}

.owl-prev, .owl-next{
    background-color: transparent !important;
    opacity: 1;
            -webkit-transition: all .4s ease-in-out;
               -moz-transition: all .4s ease-in-out;
                -ms-transition: all .4s ease-in-out;
                 -o-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
}
.owl-prev:hover, .owl-next:hover{
    opacity: 0.4;
            -webkit-transition: all .4s ease-in-out;
               -moz-transition: all .4s ease-in-out;
                -ms-transition: all .4s ease-in-out;
                 -o-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
}
.owl-nav-sticky-wide .owl-prev{
	left: 20px;
}
.owl-nav-sticky-wide .owl-next{
	right: 20px;
}

.owl-nav-sticky-extra-wide .owl-prev{
	left: -50px;
}
.owl-nav-sticky-extra-wide .owl-next{
	right: -50px;
}
.owl-nav-sticky-center .owl-prev{
	margin-left: -60px !important;
}
.owl-nav-sticky-center .owl-next{
	margin-right: -60px !important;
}

.owl-nav-sticky-left .owl-prev{
	left: 20px;
}
.owl-nav-sticky-left .owl-next{
	left: 120px;
}
.owl-nav-sticky-right .owl-prev{
	right: 120px;
}
.owl-nav-sticky-right .owl-next{
	right: 20px;
}






