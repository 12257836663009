#subMenu{
	display: none;
  }
  ul.subMenu li:hover{ 
	background: none repeat scroll 0 0 rgba(250, 250, 250, 0.8);
	opacity: 1; 
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
 
}

ul.subMenuMobile{
	display: none;
}
 ul.subMenuMobile li:hover{ 
	background: none repeat scroll 0 0 rgba(250, 250, 250, 0.8);
	opacity: 1; 
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
 
}

ul.subMenuMobile {
    list-style-type: none;
    margin: 5px;
    padding: 5px; 
    background-color: #fff;
}

ul.subMenuMobile li {
    position: relative;
    display: inline-block;
    padding-right: 10px;
    background-color: #fff;
}

ul.subMenuMobile > li {
    margin-right: -5px;
}

ul.subMenuMobile > li:first-child {
    border-left: 0;
}

ul.subMenuMobile > li:last-child {
    margin-right: 0;
}

ul.subMenuMobile li a {    
    color: #666;
    display: block;
    font-size: 8px;
    font-weight: normal;
    line-height: 5px;
    /*padding: 6px 6px;*/
    text-shadow: none;
    transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -webkit-transition: background-color 0.5s ease-out;
}

ul.subMenuMobile li a > i {
    display: none !important;
}

ul.subMenuMobile li:hover {
    background-color: #eee;
    text-decoration: none;
}

ul.subMenuMobile li ul {
    margin: 10px;
    list-style-type: none;
}

ul.subMenuMobile li ul li {
    background-color: #fff;
    padding-left: 25px;
    text-align: center;
}

ul.subMenuMobile li > ul {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999;
    width: 100%;
}

ul.subMenuMobile li > ul > li ul {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 999;
    width: 100%;
}

ul.subMenuMobile.collapsed li {
    display: block;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

}

ul.subMenuMobile.collapsed li a {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

}

ul.subMenuMobile.collapsed li .sub-collapser, 
ul.subMenuMobile > li > a:first-child{    
    font-size: 18px;
    line-height: 15px;
    padding: 5px 15px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

ul.subMenuMobile.collapsed li > ul {
    display: none;
    position: absolute;
    padding: 0;
    list-style: none;
}