
  /*MOBILE ONLY NAVIGATION (SLIM MENU)*/
.mobile-nav.signature-phil {
    margin-bottom: 15px;
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 101;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; 
}

.menu-collapser { 
    position: relative;
    background-color: #FFF;
    background-size: auto 50px;
    background-repeat: no-repeat;
    background-position: 10px center;
    color: #444;
    width: 100%;
    height: 80px;
    line-height: 48px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 35px;
   /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);*/
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.collapse-button {
    position: absolute;
    right: 8px;
    top: 50%;
    width: 40px;
    background-repeat: repeat-x;
    color: #FFFFFF;
    padding: 7px 10px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.collapse-button:hover, .collapse-button:focus {
    background-image: none;
    background-color: #eee;
    color: #000;
}

.collapse-button .icon-bar {
    background-color: #000;
    border-radius: 1px 1px 1px 1px;
    display: block;
    height: 2px;
    width: 18px;
    margin: 2px 0;
}

ul.slimmenu {
    list-style-type: none;
    margin: 0;
    padding: 50px;
    padding-bottom: 100px;
    width: 100%;
    background-color: #fff;
}

ul.slimmenu li {
    position: relative;
    display: inline-block;
    background-color: #fff;
}

ul.slimmenu > li {
    margin-right: -5px;
}

ul.slimmenu > li:first-child {
    border-left: 0;
}

ul.slimmenu > li:last-child {
    margin-right: 0;
}

ul.slimmenu li a {    
    color: #666;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    padding: 6px 0;
    text-shadow: none;
    transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -webkit-transition: background-color 0.5s ease-out;
}

ul.slimmenu li a > i {
    display: none !important;
}

ul.slimmenu li:hover {
    background-color: #eee;
    text-decoration: none;
}

ul.slimmenu li ul {
    margin: 0;
    list-style-type: none;
}

ul.slimmenu li ul li {
    background-color: #fff;
    padding-left: 0px;
    text-align: center;
}

ul.slimmenu li > ul {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999;
    width: 100%;
}

ul.slimmenu li > ul > li ul {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 999;
    width: 100%;
}

ul.slimmenu.collapsed li {
    display: block;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

ul.slimmenu.collapsed li a {
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

ul.slimmenu.collapsed li .sub-collapser, 
ul.slimmenu > li > a:first-child{    
    font-size: 24px;
    line-height: 31px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}

ul.slimmenu.collapsed li > ul {
    display: none;
    position: static;
    padding: 0;
    list-style: none;
}