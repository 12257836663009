/*
--------------------------------------------------------

Item Information

--------------------------------------------------------

Author: IncisiveStudio
Author UI: http://IncisiveStudio.com
Item Name: Sinister
Description: CSS Animation and Effects
Version: 1.6.4

-------------------------------------------------------- */
.ImageWrapper {/*-webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);-moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);-ms-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);-o-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);*/
	display: block;overflow: hidden;position: relative;}
/*  =============================================
Button Layout and Color Scheme
============================================= */
.WhiteRounded {background-color: #ffffff;border: medium none;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;-o-border-radius: 50%;border-radius: 50%;-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-ms-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-o-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);}
.WhiteRounded > a {color: #222222;display: block;font-weight: normal;}
.RedRounded {background-color: #D8322B;border: medium none;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;-o-border-radius: 50%;border-radius: 50%;-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-ms-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-o-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);}
.RedRounded > a {color: #FFFFFF;display: block;font-weight: normal;}
.BlackRounded {background-color: #222222;border: medium none;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;-o-border-radius: 50%;border-radius: 50%;-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-ms-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-o-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);}
.BlackRounded > a {color: #ffffff;display: block;font-weight: normal;}
.WhiteHollowRounded {border: 1px solid #ffffff;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;-o-border-radius: 50%;border-radius: 50%;}
.WhiteHollowRounded > a {color: #ffffff;display: block;font-weight: normal;}
.BlackHollowRounded {border: 1px solid #222222;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;-o-border-radius: 50%;border-radius: 50%;}
.BlackHollowRounded > a {color: #222222;display: block;font-weight: normal;}
.WhiteSquare {background-color: #ffffff;border: medium none;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 4px 4px 4px;-moz-border-radius: 4px 4px 4px;-ms-border-radius: 4px 4px 4px;-o-border-radius: 4px 4px 4px;border-radius: 4px 4px 4px;-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-ms-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-o-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);}
.WhiteSquare > a {color: #222222;display: block;font-weight: normal;}
.BlackSquare {background-color: #222222;border: medium none;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 4px 4px 4px;-moz-border-radius: 4px 4px 4px;-ms-border-radius: 4px 4px 4px;-o-border-radius: 4px 4px 4px;border-radius: 4px 4px 4px;-webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-ms-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);-o-box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);box-shadow: 0 0 1px rgba(0, 0, 0, 0.8), inset 0 0 2px rgba(0, 0, 0, .1);}
.BlackSquare > a {color: #ffffff;display: block;font-weight: normal;}
.WhiteHollowSquare {border: 1px solid #ffffff;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 4px 4px 4px;-moz-border-radius: 4px 4px 4px;-ms-border-radius: 4px 4px 4px;-o-border-radius: 4px 4px 4px;border-radius: 4px 4px 4px;}
.WhiteHollowSquare > a {color: #ffffff;display: block;font-weight: normal;}
.BlackHollowSquare {border: 1px solid #222222;display: inline-block !important;float: none !important;font-size: 14px;font-weight: normal;height: 40px;line-height: 40px;margin: 0 2px;text-align: center;width: 40px;-webkit-border-radius: 4px 4px 4px;-moz-border-radius: 4px 4px 4px;-ms-border-radius: 4px 4px 4px;-o-border-radius: 4px 4px 4px;border-radius: 4px 4px 4px;}
.BlackHollowSquare > a {color: #222222;display: block;font-weight: normal;}
.VisibleButtons {margin: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;}
.VisibleImageOverlay {position: absolute;background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);width: 100%;height: 100%;top: 0;left: 0;opacity: .6;visibility: visible;}
/*  =============================================
Overlay Effects
============================================= */
.ImageWrapper .ImageOverlayH {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);bottom: 0;display: block;height: 100%;left: 0;opacity: 0;position: absolute;right: 0;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayH {opacity: 1;}
.ImageWrapper .ImageOverlayHe {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 0;display: block;height: 0;left: 0;opacity: 0;position: absolute;top: 50%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayHe {height: 100%;top: 0;opacity: 1;}
.ImageWrapper .ImageOverlayLi:after {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;content:"";display: block;left: 0;opacity: 0;position: absolute;top: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayLi:after {top: -50%;opacity: 1;}
.ImageWrapper .ImageOverlayLi:before {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);bottom: -100%;height: 100%;content:"";display: block;left: 0;opacity: 0;position: absolute;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayLi:before {bottom: -50%;opacity: 1;}
.ImageWrapper .ImageOverlayBe:after {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;content:"";display: block;left: 0;opacity: 0;position: absolute;top: -100%;-webkit-transition: all 0.6s ease 0s;-moz-transition: all 0.6s ease 0s;-ms-transition: all 0.6s ease 0s;-o-transition: all 0.6s ease 0s;transition: all 0.6s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayBe:after {top: 50%;opacity: 1;}
.ImageWrapper .ImageOverlayBe:before {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);bottom: -100%;height: 100%;content:"";display: block;left: 0;opacity: 0;position: absolute;-webkit-transition: all 0.6s ease 0s;-moz-transition: all 0.6s ease 0s;-ms-transition: all 0.6s ease 0s;-o-transition: all 0.6s ease 0s;transition: all 0.6s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayBe:before {bottom: 50%;opacity: 1;}
.ImageWrapper .ImageOverlayB {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;left: 50%;opacity: 0;position: absolute;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 0;}
.ImageWrapper:hover .ImageOverlayB {left: 0;width: 100%;opacity: 1;}
.ImageWrapper .ImageOverlayC:after {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;content:"";display: block;right: -100%;opacity: 0;position: absolute;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayC:after {right: -50%;opacity: 1;}
.ImageWrapper .ImageOverlayC:before {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;content:"";display: block;left: -100%;opacity: 0;position: absolute;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayC:before {left: -50%;opacity: 1;}
.ImageWrapper .ImageOverlayN:after {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;content:"";display: block;right: -100%;opacity: 0;position: absolute;top: 0;-webkit-transition: all 0.6s ease 0s;-moz-transition: all 0.6s ease 0s;-ms-transition: all 0.6s ease 0s;-o-transition: all 0.6s ease 0s;transition: all 0.6s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayN:after {right: 50%;opacity: 1;}
.ImageWrapper .ImageOverlayN:before {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;content:"";display: block;left: -100%;opacity: 0;position: absolute;top: 0;-webkit-transition: all 0.6s ease 0s;-moz-transition: all 0.6s ease 0s;-ms-transition: all 0.6s ease 0s;-o-transition: all 0.6s ease 0s;transition: all 0.6s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayN:before {left: 50%;opacity: 1;}
.ImageWrapper .ImageOverlayO {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;left: -100%;opacity: 0;position: absolute;top: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayO {left: 0;top: 0;opacity: 1;}
.ImageWrapper .ImageOverlayF {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;right: -100%;opacity: 0;position: absolute;top: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayF {right: 0;top: 0;opacity: 1;}
.ImageWrapper .ImageOverlayNe {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;left: -100%;opacity: 0;position: absolute;bottom: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayNe {bottom: 0;left: 0;opacity: 1;}
.ImageWrapper .ImageOverlayNa {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;right: -100%;opacity: 0;position: absolute;bottom: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayNa {bottom: 0;right: 0;opacity: 1;}
.ImageWrapper .ImageOverlayMg {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;left: 0;opacity: 0;position: absolute;top: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayMg {top: 0;opacity: 1;}
.ImageWrapper .ImageOverlayAl {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;left: 0;opacity: 0;position: absolute;bottom: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayAl {bottom: 0;opacity: 1;}
.ImageWrapper .ImageOverlaySi {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;top: 0;opacity: 0;position: absolute;right: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlaySi {right: 0;opacity: 1;}
.ImageWrapper .ImageOverlayP {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.5);height: 100%;display: block;top: 0;opacity: 0.5;position: absolute;left: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;}
.ImageWrapper:hover .ImageOverlayP {left: 0;opacity: 0.5;}
.ImageWrapper .ImageOverlayS {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;top: 0;opacity: 0;position: absolute;left: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;-webkit-transform: rotate(180deg) scale(0);-moz-transform: rotate(180deg) scale(0);-ms-transform: rotate(180deg) scale(0);-o-transform: rotate(180deg) scale(0);transform: rotate(180deg) scale(0);}
.ImageWrapper:hover .ImageOverlayS {-webkit-transform: rotate(0deg) scale(1);-moz-transform: rotate(0deg) scale(1);-ms-transform: rotate(0deg) scale(1);-o-transform: rotate(0deg) scale(1);transform: rotate(0deg) scale(1);opacity: 1;}
.ImageWrapper .ImageOverlayCl {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);height: 100%;display: block;top: 0;opacity: 0;position: absolute;left: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;-webkit-transform: rotate(-180deg) scale(0);-moz-transform: rotate(-180deg) scale(0);-ms-transform: rotate(-180deg) scale(0);-o-transform: rotate(-180deg) scale(0);transform: rotate(-180deg) scale(0);}
.ImageWrapper:hover .ImageOverlayCl {-webkit-transform: rotate(0deg) scale(1);-moz-transform: rotate(0deg) scale(1);-ms-transform: rotate(0deg) scale(1);-o-transform: rotate(0deg) scale(1);transform: rotate(0deg) scale(1);opacity: 1;}
.ImageWrapper .ImageOverlayArLeft:before {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);opacity: 0;content:"";display: block;position: absolute;top: -50%;-webkit-transition: all 0.2s ease 0s;-moz-transition: all 0.2s ease 0s;-ms-transition: all 0.2s ease 0s;-o-transition: all 0.2s ease 0s;transition: all 0.2s ease 0s;width: 100%;height: 100%;left: -100%;overflow: hidden;}
.ImageWrapper .ImageOverlayArLeft:after {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);opacity: 0;content:"";display: block;position: absolute;top: 50%;-webkit-transition: all 0.2s ease .2s;-moz-transition: all 0.2s ease .2s;-ms-transition: all 0.2s ease .2s;-o-transition: all 0.2s ease .2s;transition: all 0.2s ease .2s;width: 100%;height: 100%;left: -100%;overflow: hidden;}
.ImageWrapper .ImageOverlayArRight:before {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);opacity: 0;content:"";display: block;position: absolute;top: -50%;-webkit-transition: all 0.2s ease .3s;-moz-transition: all 0.2s ease .3s;-ms-transition: all 0.2s ease .3s;-o-transition: all 0.2s ease .3s;transition: all 0.2s ease .3s;width: 100%;height: 100%;right: -100%;overflow: hidden;}
.ImageWrapper .ImageOverlayArRight:after {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);opacity: 0;content:"";display: block;position: absolute;top: 50%;-webkit-transition: all 0.2s ease .5s;-moz-transition: all 0.2s ease .5s;-ms-transition: all 0.2s ease .5s;-o-transition: all 0.2s ease .5s;transition: all 0.2s ease .5s;width: 100%;height: 100%;right: -100%;overflow: hidden;}
.ImageWrapper:hover .ImageOverlayArLeft:before, .ImageWrapper:hover .ImageOverlayArLeft:after {opacity: 1;left: 50%;}
.ImageWrapper:hover .ImageOverlayArRight:before, .ImageWrapper:hover .ImageOverlayArRight:after {opacity: 1;right: 50%;}
.ImageWrapper .ImageOverlayK {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);opacity: 0;position: absolute;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform-origin: top left;-moz-transform-origin: top left;-ms-transform-origin: top left;-o-transform-origin: top left;transform-origin: top left;-webkit-transform-style: preserve-3D;-moz-transform-style: preserve-3D;-ms-transform-style: preserve-3D;-o-transform-style: preserve-3D;transform-style: preserve-3D;-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);transform: rotate(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .ImageOverlayK {-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);opacity: 1;}
.ImageWrapper .ImageOverlayCa {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);opacity: 0;position: absolute;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform-origin: top right;-moz-transform-origin: top right;-ms-transform-origin: top right;-o-transform-origin: top right;transform-origin: top right;-webkit-transform-style: preserve-3D;-moz-transform-style: preserve-3D;-ms-transform-style: preserve-3D;-o-transform-style: preserve-3D;transform-style: preserve-3D;-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);transform: rotate(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .ImageOverlayCa {-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);opacity: 1;}
.ImageWrapper .ImageOverlaySc {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);opacity: 0;position: absolute;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform-origin: bottom left;-moz-transform-origin: bottom left;-ms-transform-origin: bottom left;-o-transform-origin: bottom left;transform-origin: bottom left;-webkit-transform-style: preserve-3D;-moz-transform-style: preserve-3D;-ms-transform-style: preserve-3D;-o-transform-style: preserve-3D;transform-style: preserve-3D;-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);transform: rotate(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .ImageOverlaySc {-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);opacity: 1;}
.ImageWrapper .ImageOverlayTi {background: none repeat scroll 0 0 rgba(0, 0, 0, 0.8);opacity: 0;position: absolute;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform-origin: bottom right;-moz-transform-origin: bottom right;-ms-transform-origin: bottom right;-o-transform-origin: bottom right;transform-origin: bottom right;-webkit-transform-style: preserve-3D;-moz-transform-style: preserve-3D;-ms-transform-style: preserve-3D;-o-transform-style: preserve-3D;transform-style: preserve-3D;-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);transform: rotate(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .ImageOverlayTi {-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);opacity: 1;}
/*  =============================================
Background Transform
============================================= */
.GrayScale {-webkit-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");filter: gray;-webkit-filter: grayscale(100%);-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);opacity: .6;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.GrayScale:hover {-webkit-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");-webkit-filter: grayscale(0%);-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);opacity: 1;}
.BackgroundS img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.BackgroundS:hover img {-webkit-transform: scale(1.2);-moz-transform: scale(1.2);-ms-transform: scale(1.2);-o-transform: scale(1.2);transform: scale(1.2);}
.BackgroundRR img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.BackgroundRR:hover img {-webkit-transform: rotate(-10deg) scale(1.4);-moz-transform: rotate(-10deg) scale(1.4);-ms-transform: rotate(-10deg) scale(1.4);-o-transform: rotate(-10deg) scale(1.4);transform: rotate(-10deg) scale(1.4);}
.BackgroundR img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.BackgroundR:hover img {-webkit-transform: rotate(10deg) scale(1.4);-moz-transform: rotate(10deg) scale(1.4);-ms-transform: rotate(10deg) scale(1.4);-o-transform: rotate(10deg) scale(1.4);transform: rotate(10deg) scale(1.4);}
.BackgroundRS img {-webkit-transform: scale(1.2);-moz-transform: scale(1.2);-ms-transform: scale(1.2);-o-transform: scale(1.2);transform: scale(1.2);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.BackgroundRS:hover img {-webkit-transform: scale(1.0);-moz-transform: scale(1.0);-ms-transform: scale(1.0);-o-transform: scale(1.0);transform: scale(1.0);}
.BackgroundF img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.BackgroundF:hover img {opacity: 0;}
.BackgroundFS img {-webkit-transform: scale(1.0);-moz-transform: scale(1.0);-ms-transform: scale(1.0);-o-transform: scale(1.0);transform: scale(1.0);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.BackgroundFS:hover img {-webkit-transform: scale(10);-moz-transform: scale(10);-ms-transform: scale(10);-o-transform: scale(10);transform: scale(10);opacity: 0;}
.BackgroundFRS img {-webkit-transform: scale(1.0);-moz-transform: scale(1.0);-ms-transform: scale(1.0);-o-transform: scale(1.0);transform: scale(1.0);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.BackgroundFRS:hover img {-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);-o-transform: scale(0);transform: scale(0);opacity: 0;}
.SquareCircle {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.SquareCircle:hover {-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;-o-border-radius: 50%;border-radius: 50%;}
.CircleSquare {-webkit-border-radius: 50%;-moz-border-radius: 50%;-ms-border-radius: 50%;-o-border-radius: 50%;border-radius: 50%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.CircleSquare:hover {-webkit-border-radius:0;-moz-border-radius:0;-ms-border-radius:0;-o-border-radius:0;border-radius:0;}
/*  =============================================
Cubic Effects
============================================= */
.ImageWrapper .CStyleH {margin: 0;opacity: 0;position: absolute;text-align: center;top: 0;visibility: hidden;width: 100%;-webkit-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-moz-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-ms-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-o-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);}
.ImageWrapper:hover .CStyleH {margin-top: -20px;opacity: 1;top: 50%;visibility: visible;}
.ImageWrapper .CStyleHe {visibility: hidden;margin: 0;opacity: 0;position: absolute;text-align: center;bottom: 0;width: 100%;-webkit-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-moz-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-ms-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-o-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);}
.ImageWrapper:hover .CStyleHe {margin-bottom: -20px;opacity: 1;bottom: 50%;visibility: visible;}
.ImageWrapper .CStyleLi {visibility: hidden;margin: 0;opacity: 0;position: absolute;text-align: right;right: 0;width: 100%;top: 50%;margin-top: -20px;-webkit-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-moz-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-ms-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-o-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);}
.ImageWrapper:hover .CStyleLi {margin-right: -42px;opacity: 1;right: 50%;visibility: visible;}
.ImageWrapper .CStyleBe {visibility: hidden;margin: 0;opacity: 0;position: absolute;text-align: left;left: 0;width: 100%;top: 50%;margin-top: -20px;-webkit-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-moz-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-ms-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-o-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);}
.ImageWrapper:hover .CStyleBe {margin-left: -42px;opacity: 1;left: 50%;visibility: visible;}
.ImageWrapper .CStyleB {visibility: hidden;margin: 0;opacity: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;-webkit-transform: scale(0.2);-moz-transform: scale(0.2);-ms-transform: scale(0.2);-o-transform: scale(0.2);transform: scale(0.2);-webkit-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-moz-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-ms-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);-o-transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);transition: all 400ms cubic-bezier(1.000, -0.600, 0.570, -0.150);}
.ImageWrapper:hover .CStyleB {opacity: 1;visibility: visible;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);-o-transform: scale(1);transform: scale(1);}
.ImageWrapper .CStyleC span {position: absolute;}
.ImageWrapper .CStyleC span:nth-of-type(1) {bottom: 50%;top: 50%;left: 0;margin: -20px 0 0 -68px;visibility: hidden;opacity: 0;-webkit-transition: all 400ms cubic-bezier(1.000, 0, 0.570, 0) !important;-webkit-transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;-moz-transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;-ms-transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;-o-transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;}
.ImageWrapper .CStyleC span:nth-of-type(2) {bottom: 50%;top: 50%;left: 50%;right: 50%;margin: -20px 0 0 -20px;visibility: hidden;opacity: 0;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);-o-transform: scale(0);transform: scale(0);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .CStyleC span:nth-of-type(3) {bottom: 50%;top: 50%;right: 0;margin: -20px -68px 0 0;visibility: hidden;opacity: 0;-webkit-transition: all 400ms cubic-bezier(1.000, 0, 0.570, 0) !important;-webkit-transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;-moz-transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;-ms-transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;-o-transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;transition: all 400ms cubic-bezier(1.000, -0.360, 0.570, -0.150)!important;}
.ImageWrapper:hover .CStyleC span:nth-of-type(1) {left: 50%;visibility: visible;opacity: 1;}
.ImageWrapper:hover .CStyleC span:nth-of-type(2) {visibility: visible;opacity: 1;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);-o-transform: scale(1);transform: scale(1);}
.ImageWrapper:hover .CStyleC span:nth-of-type(3) {right: 50%;visibility: visible;opacity: 1;}
/*  =============================================
Button Effects
============================================= */
.ImageWrapper .StyleH {visibility: hidden;margin: 0;opacity: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleH {opacity: 1;visibility: visible;}
.ImageWrapper .StyleHe {margin: 0;opacity: 0;position: absolute;text-align: center;top: 0;visibility: hidden;width: 100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleHe {margin-top: -20px;opacity: 1;top: 50%;visibility: visible;}
.ImageWrapper .StyleLi {visibility: hidden;margin: 0;opacity: 0;position: absolute;text-align: center;bottom: 0;width: 100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleLi {margin-bottom: -20px;opacity: 1;bottom: 50%;visibility: visible;}
.ImageWrapper .StyleBe {visibility: hidden;opacity: 0;position: absolute;text-align: right;right: 0;width: 100%;top: 50%;margin-top: -20px;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleBe {margin-right: -42px;opacity: 1;right: 50%;visibility: visible;}
.ImageWrapper .StyleB {visibility: hidden;opacity: 0;position: absolute;text-align: left;left: 0;width: 100%;top: 50%;margin-top: -20px;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleB {margin-left: -42px;opacity: 1;left: 50%;visibility: visible;}
.ImageWrapper .StyleC {visibility: hidden;opacity: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;-webkit-transform: scale(0.2);-moz-transform: scale(0.2);-ms-transform: scale(0.2);-o-transform: scale(0.2);transform: scale(0.2);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleC {opacity: 1;visibility: visible;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);-o-transform: scale(1);transform: scale(1);}
.ImageWrapper .StyleN {visibility: hidden;opacity: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;visibility: visible;-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-ms-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleN {opacity: 1;visibility: visible;-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg);}
.ImageWrapper .StyleO span {position: absolute;}
.ImageWrapper .StyleO span:nth-of-type(1) {bottom: 50%;top: 50%;left: 50%;margin: -20px 0 0 -42px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleO span:nth-of-type(2) {bottom: 50%;top: 50%;right: 50%;margin: -20px -42px 0 0;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleO span:nth-of-type(1) {opacity: 1;visibility: visible;-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg);}
.ImageWrapper:hover .StyleO span:nth-of-type(2) {opacity: 1;visibility: visible;-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg);}
.ImageWrapper .StyleF {visibility: hidden;-webkit-transform: scale(0.5) rotateX(360deg);-moz-transform: scale(0.5) rotateX(360deg);-ms-transform: scale(0.5) rotateX(360deg);-o-transform: scale(0.5) rotateX(360deg);transform: scale(0.5) rotateX(360deg);margin: 0;opacity: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleF {opacity: 1;-webkit-transform: scale(1) rotateX(0deg);-moz-transform: scale(1) rotateX(0deg);-ms-transform: scale(1) rotateX(0deg);-o-transform: scale(1) rotateX(0deg);transform: scale(1) rotateX(0deg);visibility: visible;}
.ImageWrapper .StyleNe {visibility: hidden;margin: 0;-webkit-transform:rotateY(0deg);-moz-transform:rotateY(0deg);-ms-transform:rotateY(0deg);-o-transform:rotateY(0deg);transform:rotateY(0deg);opacity: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleNe {opacity: 1;-webkit-transform:rotateY(360deg);-moz-transform:rotateY(360deg);-ms-transform:rotateY(360deg);-o-transform:rotateY(360deg);transform:rotateY(360deg);visibility: visible;}
.ImageWrapper .StyleNa {visibility: hidden;-webkit-transform: scale(0.2) rotateY(360deg);-moz-transform: scale(0.2) rotateY(360deg);-ms-transform: scale(0.2) rotateY(360deg);-o-transform: scale(0.2) rotateY(360deg);transform: scale(0.2) rotateY(360deg);margin: 0;opacity: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleNa {opacity: 1;-webkit-transform: scale(1) rotateY(0deg);-moz-transform: scale(1) rotateY(0deg);-ms-transform: scale(1) rotateY(0deg);-o-transform: scale(1) rotateY(0deg);transform: scale(1) rotateY(0deg);visibility: visible;}
.ImageWrapper .StyleMg span {position: absolute;}
.ImageWrapper .StyleMg span:nth-of-type(1) {bottom: 50%;top: 50%;left: 50%;margin: -20px 0 0 -82px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleMg span:nth-of-type(2) {bottom: 50%;top: 50%;right: 50%;margin: -20px -82px 0 0;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleMg span:nth-of-type(1) {margin: -20px 0 0 -42px;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleMg span:nth-of-type(2) {margin: -20px -42px 0 0;visibility: visible;opacity: 1;}
.ImageWrapper .StyleAl span {position: absolute;}
.ImageWrapper .StyleAl span:nth-of-type(1) {top: 0;left: 50%;margin: -20px 0 0 -42px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleAl span:nth-of-type(2) {bottom: 0;right: 50%;margin: 0 -42px -20px 0;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleAl span:nth-of-type(1) {top: 50%;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleAl span:nth-of-type(2) {bottom: 50%;visibility: visible;opacity: 1;}
.ImageWrapper .StyleSi span {position: absolute;}
.ImageWrapper .StyleSi span:nth-of-type(1) {bottom: 0;left: 50%;margin: 0 0 -20px -42px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleSi span:nth-of-type(2) {top: 0;right: 50%;margin: -20px -42px 0 0;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleSi span:nth-of-type(1) {bottom: 50%;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleSi span:nth-of-type(2) {top: 50%;visibility: visible;opacity: 1;}
.ImageWrapper .StyleP span {position: absolute;}
.ImageWrapper .StyleP span:nth-of-type(1) {top: 0;left: 0;margin: -40px 0 0 -40px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleP span:nth-of-type(2) {bottom: 0;right: 0;margin: 0 -40px -40px 0;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleP span:nth-of-type(1) {top: 50%;left: 50%;margin: -20px 0 0 -42px;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleP span:nth-of-type(2) {bottom: 50%;right: 50%;margin: 0 -42px -20px 0;visibility: visible;opacity: 1;}
.ImageWrapper .StyleS span {position: absolute;}
.ImageWrapper .StyleS span:nth-of-type(1) {bottom: 0;left: 0;margin: -40px 0 0 -40px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleS span:nth-of-type(2) {top: 0;right: 0;margin: 0 -40px -40px 0;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleS span:nth-of-type(1) {bottom: 50%;left: 50%;margin: 0 0 -20px -42px;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleS span:nth-of-type(2) {top: 50%;right: 50%;margin: -20px -42px 0 0;visibility: visible;opacity: 1;}
.ImageWrapper .StyleCl {visibility: hidden;margin: 0;opacity: 0;position: absolute;text-align: center;width: 100%;top: 50%;margin-top: -20px;visibility: visible;-webkit-transform:rotateX(0deg);-moz-transform:rotateX(0deg);-ms-transform:rotateX(0deg);-o-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleCl {opacity: 1;visibility: visible;-webkit-transform:rotateX(360deg);-moz-transform:rotateX(360deg);-ms-transform:rotateX(360deg);-o-transform:rotateX(360deg);transform:rotateX(360deg);}
.ImageWrapper .StyleAr span {position: absolute;}
.ImageWrapper .StyleAr span:nth-of-type(1) {top: 50%;bottom: 50%;left: 50%;margin: -20px 0 0 -42px;visibility: hidden;opacity: 0;-webkit-transform: scale(0.2) rotate(0deg);-moz-transform: scale(0.2) rotate(0deg);-ms-transform: scale(0.2) rotate(0deg);-o-transform: scale(0.2) rotate(0deg);transform: scale(0.2) rotate(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleAr span:nth-of-type(2) {top: 50%;bottom: 50%;right: 50%;margin: -20px -42px -0 0;visibility: hidden;opacity: 0;-webkit-transform: scale(0.2) rotate(0deg);-moz-transform: scale(0.2) rotate(0deg);-ms-transform: scale(0.2) rotate(0deg);-o-transform: scale(0.2) rotate(0deg);transform: scale(0.2) rotate(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleAr span:nth-of-type(1) {visibility: visible;opacity: 1;-webkit-transform: scale(1) rotate(360deg);-moz-transform: scale(1) rotate(360deg);-ms-transform: scale(1) rotate(360deg);-o-transform: scale(1) rotate(360deg);transform: scale(1) rotate(360deg);}
.ImageWrapper:hover .StyleAr span:nth-of-type(2) {visibility: visible;opacity: 1;-webkit-transform: scale(1) rotate(360deg);-moz-transform: scale(1) rotate(360deg);-ms-transform: scale(1) rotate(360deg);-o-transform: scale(1) rotate(360deg);transform: scale(1) rotate(360deg);}
.ImageWrapper .StyleK span {position: absolute;}
.ImageWrapper .StyleK span:nth-of-type(1) {top: 50%;bottom: 50%;left: 50%;margin: -20px 0 0 -42px;visibility: hidden;opacity: 0;-webkit-transform:rotateY(0deg);-moz-transform:rotateY(0deg);-ms-transform:rotateY(0deg);-o-transform:rotateY(0deg);transform:rotateY(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleK span:nth-of-type(2) {top: 50%;bottom: 50%;right: 50%;margin: -20px -42px -0 0;visibility: hidden;opacity: 0;-webkit-transform:rotateY(0deg);-moz-transform:rotateY(0deg);-ms-transform:rotateY(0deg);-o-transform:rotateY(0deg);transform:rotateY(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleK span:nth-of-type(1) {visibility: visible;opacity: 1;-webkit-transform:rotateY(360deg);-moz-transform:rotateY(360deg);-ms-transform:rotateY(360deg);-o-transform:rotateY(360deg);transform:rotateY(360deg);}
.ImageWrapper:hover .StyleK span:nth-of-type(2) {visibility: visible;opacity: 1;-webkit-transform:rotateY(360deg);-moz-transform:rotateY(360deg);-ms-transform:rotateY(360deg);-o-transform:rotateY(360deg);transform:rotateY(360deg);}
.ImageWrapper .StyleCa span {position: absolute;}
.ImageWrapper .StyleCa span:nth-of-type(1) {top: 50%;bottom: 50%;left: 50%;margin: -20px 0 0 -42px;visibility: hidden;opacity: 0;-webkit-transform: scale(0.2) rotateY(0deg);-moz-transform: scale(0.2) rotateY(0deg);-ms-transform: scale(0.2) rotateY(0deg);-o-transform: scale(0.2) rotateY(0deg);transform: scale(0.2) rotateY(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleCa span:nth-of-type(2) {top: 50%;bottom: 50%;right: 50%;margin: -20px -42px -0 0;visibility: hidden;opacity: 0;-webkit-transform: scale(0.2) rotateY(0deg);-moz-transform: scale(0.2) rotateY(0deg);-ms-transform: scale(0.2) rotateY(0deg);-o-transform: scale(0.2) rotateY(0deg);transform: scale(0.2) rotateY(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleCa span:nth-of-type(1) {visibility: visible;opacity: 1;-webkit-transform: scale(1) rotateY(360deg);-moz-transform: scale(1) rotateY(360deg);-ms-transform: scale(1) rotateY(360deg);-o-transform: scale(1) rotateY(360deg);transform: scale(1) rotateY(360deg);}
.ImageWrapper:hover .StyleCa span:nth-of-type(2) {visibility: visible;opacity: 1;-webkit-transform: scale(1) rotateY(360deg);-moz-transform: scale(1) rotateY(360deg);-ms-transform: scale(1) rotateY(360deg);-o-transform: scale(1) rotateY(360deg);transform: scale(1) rotateY(360deg);}
.ImageWrapper .StyleSc span {position: absolute;}
.ImageWrapper .StyleSc span:nth-of-type(1) {bottom: 50%;top: 50%;left: 0;margin: -20px 0 0 -68px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleSc span:nth-of-type(2) {top: 0;right: 50%;left: 50%;margin: -20px 0 0 -20px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleSc span:nth-of-type(3) {bottom: 50%;top: 50%;right: 0;margin: -20px -68px 0 0;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleSc span:nth-of-type(1) {left: 50%;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleSc span:nth-of-type(2) {top: 50%;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleSc span:nth-of-type(3) {right: 50%;visibility: visible;opacity: 1;}
.ImageWrapper .StyleTi span {position: absolute;}
.ImageWrapper .StyleTi span:nth-of-type(1) {bottom: 50%;top: 50%;left: 0;margin: -20px 0 0 -68px;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleTi span:nth-of-type(2) {bottom: 50%;top: 50%;left: 50%;right: 50%;margin: -20px 0 0 -20px;visibility: hidden;opacity: 0;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);-o-transform: scale(0);transform: scale(0);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper .StyleTi span:nth-of-type(3) {bottom: 50%;top: 50%;right: 0;margin: -20px -68px 0 0;visibility: hidden;opacity: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .StyleTi span:nth-of-type(1) {left: 50%;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleTi span:nth-of-type(2) {visibility: visible;opacity: 1;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);-o-transform: scale(1);transform: scale(1);}
.ImageWrapper:hover .StyleTi span:nth-of-type(3) {right: 50%;visibility: visible;opacity: 1;}
.ImageWrapper .StyleV span {position: absolute;}
.ImageWrapper .StyleV span:nth-of-type(1) {top: 0;left: 50%;margin: -20px 0 0 -68px;visibility: hidden;opacity: 0;-webkit-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .2s;-moz-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .2s;-ms-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .2s;-o-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .2s;transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .2s;}
.ImageWrapper .StyleV span:nth-of-type(2) {top: 0;left: 50%;margin: -20px 0 0 -20px;visibility: hidden;opacity: 0;-webkit-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .3s;-moz-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .3s;-ms-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .3s;-o-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .3s;transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .3s;}
.ImageWrapper .StyleV span:nth-of-type(3) {top: 0;right: 50%;margin: -20px -68px 0 0;visibility: hidden;opacity: 0;-webkit-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .4s;-moz-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .4s;-ms-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .4s;-o-transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .4s;transition: all 200ms cubic-bezier(0.000, 1.135, 0.730, 1.405) .4s;}
.ImageWrapper:hover .StyleV span:nth-of-type(1) {top: 50%;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleV span:nth-of-type(2) {top: 50%;visibility: visible;opacity: 1;}
.ImageWrapper:hover .StyleV span:nth-of-type(3) {top: 50%;visibility: visible;opacity: 1;}
/*  =============================================
Plus Button Effects
============================================= */
.ImageWrapper .PStyleH {background: url(../assets/images/plus.png) no-repeat scroll center center / 60px 60px #222222;height: 100%;left: 0;opacity: 0;overflow: hidden;position: absolute;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;z-index: 9999;}
.ImageWrapper:hover .PStyleH {opacity: .6;visibility: visible;}
.ImageWrapper .PStyleHe {position: absolute;background: url(../assets/images/plus.png) no-repeat scroll center center / 100% 100% #222222;width: 100%;height: 100%;z-index: 199;-webkit-background-origin: padding-box, padding-box;-moz-background-origin: padding-box, padding-box;-ms-background-origin: padding-box, padding-box;-o-background-origin: padding-box, padding-box;background-origin: padding-box, padding-box;background-position: center center;background-repeat: no-repeat;-webkit-background-size: 10px 10px, 100% 100%;-moz-background-size: 10px 10px, 100% 100%;-ms-background-size: 10px 10px, 100% 100%;-o-background-size: 10px 10px, 100% 100%;background-size: 10px 10px, 100% 100%;opacity: 0;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .PStyleHe {opacity: .6;-webkit-background-size: 60px 60px, 100% 100%;-moz-background-size: 60px 60px, 100% 100%;-ms-background-size: 60px 60px, 100% 100%;-o-background-size: 60px 60px, 100% 100%;background-size: 60px 60px, 100% 100%;visibility: visible;}
.ImageWrapper .PStyleLi {-webkit-transform: scale(0.5) rotateY(180deg);-moz-transform: scale(0.5) rotateY(180deg);-ms-transform: scale(0.5) rotateY(180deg);-o-transform: scale(0.5) rotateY(180deg);transform: scale(0.5) rotateY(180deg);background: url(../assets/images/plus.png) no-repeat scroll center center / 60px 60px #222222;height: 100%;left: 0;opacity: 0;overflow: hidden;position: absolute;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;z-index: 9999;}
.ImageWrapper:hover .PStyleLi {opacity: .6;-webkit-transform: scale(1) rotateY(0deg);-moz-transform: scale(1) rotateY(0deg);-ms-transform: scale(1) rotateY(0deg);-o-transform: scale(1) rotateY(0deg);transform: scale(1) rotateY(0deg);visibility: visible;}
.ImageWrapper .PStyleBe {-webkit-transform: scale(0.5) rotateX(180deg);-moz-transform: scale(0.5) rotateX(180deg);-ms-transform: scale(0.5) rotateX(180deg);-o-transform: scale(0.5) rotateX(180deg);transform: scale(0.5) rotateX(180deg);background: url(../assets/images/plus.png) no-repeat scroll center center / 60px 60px #222222;height: 100%;left: 0;opacity: 0;overflow: hidden;position: absolute;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;width: 100%;z-index: 9999;}
.ImageWrapper:hover .PStyleBe {opacity: .6;-webkit-transform: scale(1) rotateX(0deg);-moz-transform: scale(1) rotateX(0deg);-ms-transform: scale(1) rotateX(0deg);-o-transform: scale(1) rotateX(0deg);transform: scale(1) rotateX(0deg);visibility: visible;}
.ImageWrapper .PStyleB {position: absolute;background: url(../assets/images/plus.png) no-repeat scroll top left / 100% 100% #222222;width: 100%;height: 100%;z-index: 199;-webkit-background-origin: padding-box, padding-box;-moz-background-origin: padding-box, padding-box;-ms-background-origin: padding-box, padding-box;-o-background-origin: padding-box, padding-box;background-origin: padding-box, padding-box;background-position: top left;background-repeat: no-repeat;-webkit-background-size: 10px 10px, 100% 100%;-moz-background-size: 10px 10px, 100% 100%;-ms-background-size: 10px 10px, 100% 100%;-o-background-size: 10px 10px, 100% 100%;background-size: 10px 10px, 100% 100%;opacity: 0;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .PStyleB {opacity: .6;-webkit-background-size: 60px 60px, 100% 100%;-moz-background-size: 60px 60px, 100% 100%;-ms-background-size: 60px 60px, 100% 100%;-o-background-size: 60px 60px, 100% 100%;background-size: 60px 60px, 100% 100%;visibility: visible;background-position: center center;}
.ImageWrapper .PStyleC {position: absolute;background: url(../assets/images/plus.png) no-repeat scroll top left / 100% 100% #222222;width: 100%;height: 100%;z-index: 199;-webkit-background-origin: padding-box, padding-box;-moz-background-origin: padding-box, padding-box;-ms-background-origin: padding-box, padding-box;-o-background-origin: padding-box, padding-box;background-origin: padding-box, padding-box;background-position: top right;background-repeat: no-repeat;-webkit-background-size: 10px 10px, 100% 100%;-moz-background-size: 10px 10px, 100% 100%;-ms-background-size: 10px 10px, 100% 100%;-o-background-size: 10px 10px, 100% 100%;background-size: 10px 10px, 100% 100%;opacity: 0;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .PStyleC {opacity: .6;-webkit-background-size: 60px 60px, 100% 100%;-moz-background-size: 60px 60px, 100% 100%;-ms-background-size: 60px 60px, 100% 100%;-o-background-size: 60px 60px, 100% 100%;background-size: 60px 60px, 100% 100%;visibility: visible;background-position: center center;}
.ImageWrapper .PStyleN {position: absolute;background: url(../assets/images/plus.png) no-repeat scroll top left / 100% 100% #222222;width: 100%;height: 100%;z-index: 199;-webkit-background-origin: padding-box, padding-box;-moz-background-origin: padding-box, padding-box;-ms-background-origin: padding-box, padding-box;-o-background-origin: padding-box, padding-box;background-origin: padding-box, padding-box;background-position: bottom right;background-repeat: no-repeat;-webkit-background-size: 10px 10px, 100% 100%;-moz-background-size: 10px 10px, 100% 100%;-ms-background-size: 10px 10px, 100% 100%;-o-background-size: 10px 10px, 100% 100%;background-size: 10px 10px, 100% 100%;opacity: 0;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .PStyleN {opacity: .6;-webkit-background-size: 60px 60px, 100% 100%;-moz-background-size: 60px 60px, 100% 100%;-ms-background-size: 60px 60px, 100% 100%;-o-background-size: 60px 60px, 100% 100%;background-size: 60px 60px, 100% 100%;visibility: visible;background-position: center center;}
.ImageWrapper .PStyleO {position: absolute;background: url(../assets/images/plus.png) no-repeat scroll top left / 100% 100% #222222;width: 100%;height: 100%;z-index: 199;-webkit-background-origin: padding-box, padding-box;-moz-background-origin: padding-box, padding-box;-ms-background-origin: padding-box, padding-box;-o-background-origin: padding-box, padding-box;background-origin: padding-box, padding-box;background-position: bottom left;background-repeat: no-repeat;-webkit-background-size: 10px 10px, 100% 100%;-moz-background-size: 10px 10px, 100% 100%;-ms-background-size: 10px 10px, 100% 100%;-o-background-size: 10px 10px, 100% 100%;background-size: 10px 10px, 100% 100%;opacity: 0;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .PStyleO {opacity: .6;-webkit-background-size: 60px 60px, 100% 100%;-moz-background-size: 60px 60px, 100% 100%;-ms-background-size: 60px 60px, 100% 100%;-o-background-size: 60px 60px, 100% 100%;background-size: 60px 60px, 100% 100%;visibility: visible;background-position: center center;}
.ImageWrapper .PStyleF {position: absolute;background: url(../assets/images/plus.png) no-repeat scroll top left / 100% 100% #222222;width: 100%;height: 100%;z-index: 199;-webkit-background-origin: padding-box, padding-box;-moz-background-origin: padding-box, padding-box;-ms-background-origin: padding-box, padding-box;-o-background-origin: padding-box, padding-box;background-origin: padding-box, padding-box;background-position: top center;background-repeat: no-repeat;-webkit-background-size: 10px 10px, 100% 100%;-moz-background-size: 10px 10px, 100% 100%;-ms-background-size: 10px 10px, 100% 100%;-o-background-size: 10px 10px, 100% 100%;background-size: 10px 10px, 100% 100%;opacity: 0;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .PStyleF {opacity: .6;-webkit-background-size: 60px 60px, 100% 100%;-moz-background-size: 60px 60px, 100% 100%;-ms-background-size: 60px 60px, 100% 100%;-o-background-size: 60px 60px, 100% 100%;background-size: 60px 60px, 100% 100%;visibility: visible;background-position: center center;}
.ImageWrapper .PStyleNe {position: absolute;background: url(../assets/images/plus.png) no-repeat scroll top left / 100% 100% #222222;width: 100%;height: 100%;z-index: 199;-webkit-background-origin: padding-box, padding-box;-moz-background-origin: padding-box, padding-box;-ms-background-origin: padding-box, padding-box;-o-background-origin: padding-box, padding-box;background-origin: padding-box, padding-box;background-position: bottom center;background-repeat: no-repeat;-webkit-background-size: 10px 10px, 100% 100%;-moz-background-size: 10px 10px, 100% 100%;-ms-background-size: 10px 10px, 100% 100%;-o-background-size: 10px 10px, 100% 100%;background-size: 10px 10px, 100% 100%;opacity: 0;top: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .PStyleNe {opacity: .6;-webkit-background-size: 60px 60px, 100% 100%;-moz-background-size: 60px 60px, 100% 100%;-ms-background-size: 60px 60px, 100% 100%;-o-background-size: 60px 60px, 100% 100%;background-size: 60px 60px, 100% 100%;visibility: visible;background-position: center center;}
/*  =============================================
Content Transform
============================================= */
.ContentWrapperH .ContentH {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperH:hover .ContentH {opacity: 1;visibility: visible;}
.ContentWrapperH .ContentH .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperH .ContentH .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperH .ContentH .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperH .ContentH .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperH .ContentH .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperHe .ContentHe {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: scale(0.0);-moz-transform: scale(0.0);-ms-transform: scale(0.0);-o-transform: scale(0.0);transform: scale(0.0);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperHe:hover .ContentHe {opacity: 1;visibility: visible;-webkit-transform: scale(1.0);-moz-transform: scale(1.0);-ms-transform: scale(1.0);-o-transform: scale(1.0);transform: scale(1.0);}
.ContentWrapperHe .ContentHe .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperHe .ContentHe .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperHe .ContentHe .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperHe .ContentHe .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperHe .ContentHe .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperLi img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperLi:hover img {-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);-o-transform: scale(0);transform: scale(0);}
.ContentWrapperLi .ContentLi {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: scale(0);-moz-transform: scale(0);-ms-transform: scale(0);-o-transform: scale(0);transform: scale(0);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperLi:hover .ContentLi {opacity: 1;visibility: visible;-webkit-transform: scale(1);-moz-transform: scale(1);-ms-transform: scale(1);-o-transform: scale(1);transform: scale(1);}
.ContentWrapperLi .ContentLi .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperLi .ContentLi .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperLi .ContentLi .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperLi .ContentLi .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperLi .ContentLi .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperBe img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;opacity: 1;}
.ContentWrapperBe:hover img {-webkit-transform: scale(10);-moz-transform: scale(10);-ms-transform: scale(10);-o-transform: scale(10);transform: scale(10);opacity: 0;}
.ContentWrapperBe .ContentBe {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperBe:hover .ContentBe {opacity: 1;visibility: visible;}
.ContentWrapperBe .ContentBe .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperBe .ContentBe .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperBe .ContentBe .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperBe .ContentBe .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperBe .ContentBe .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperB img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperB:hover img {-webkit-transform: translateY(100%);-moz-transform: translateY(100%);-ms-transform: translateY(100%);-o-transform: translateY(100%);transform: translateY(100%);}
.ContentWrapperB .ContentB {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: -100%;left: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperB:hover .ContentB {opacity: 1;visibility: visible;top: 0;}
.ContentWrapperB .ContentB .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperB .ContentB .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperB .ContentB .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperB .ContentB .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperB .ContentB .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperC img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperC:hover img {-webkit-transform: translateY(-100%);-moz-transform: translateY(-100%);-ms-transform: translateY(-100%);-o-transform: translateY(-100%);transform: translateY(-100%);}
.ContentWrapperC .ContentC {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;bottom: -100%;left: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperC:hover .ContentC {opacity: 1;visibility: visible;bottom: 0;}
.ContentWrapperC .ContentC .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperC .ContentC .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperC .ContentC .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperC .ContentC .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperC .ContentC .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperN img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperN:hover img {-webkit-transform: translateX(-100%);-moz-transform: translateX(-100%);-ms-transform: translateX(-100%);-o-transform: translateX(-100%);transform: translateX(-100%);}
.ContentWrapperN .ContentN {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;right: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperN:hover .ContentN {opacity: 1;visibility: visible;right: 0;}
.ContentWrapperN .ContentN .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperN .ContentN .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperN .ContentN .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperN .ContentN .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperN .ContentN .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperO img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperO:hover img {-webkit-transform: translateX(100%);-moz-transform: translateX(100%);-ms-transform: translateX(100%);-o-transform: translateX(100%);transform: translateX(100%);}
.ContentWrapperO .ContentO {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperO:hover .ContentO {opacity: 1;visibility: visible;left: 0;}
.ContentWrapperO .ContentO .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperO .ContentO .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperO .ContentO .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperO .ContentO .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperO .ContentO .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperF img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperF:hover img {-webkit-transform: translateX(20%);-moz-transform: translateX(20%);-ms-transform: translateX(20%);-o-transform: translateX(20%);transform: translateX(20%);}
.ContentWrapperF .ContentF {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 50%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: perspective(600px) rotateY(90deg);-moz-transform: perspective(600px) rotateY(90deg);-ms-transform: perspective(600px) rotateY(90deg);-o-transform: perspective(600px) rotateY(90deg);transform: perspective(600px) rotateY(90deg);-webkit-transform-origin: left center 0;-moz-transform-origin: left center 0;-ms-transform-origin: left center 0;-o-transform-origin: left center 0;transform-origin: left center 0;-webkit-transform-style: preserve-3d;-moz-transform-style: preserve-3d;-ms-transform-style: preserve-3d;-o-transform-style: preserve-3d;transform-style: preserve-3d;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperF:hover .ContentF {opacity: 1;visibility: visible;-webkit-transform: perspective(600px) rotateY(0deg);-moz-transform: perspective(600px) rotateY(0deg);-ms-transform: perspective(600px) rotateY(0deg);-o-transform: perspective(600px) rotateY(0deg);transform: perspective(600px) rotateY(0deg);}
.ContentWrapperF .ContentF .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperF .ContentF .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperF .ContentF .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperNe img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperNe:hover img {-webkit-transform: translateY(20%);-moz-transform: translateY(20%);-ms-transform: translateY(20%);-o-transform: translateY(20%);transform: translateY(20%);}
.ContentWrapperNe .ContentNe {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 50%;display: block;top: 0;left: 0;-webkit-transform: perspective(600px) rotateX(-90deg);-moz-transform: perspective(600px) rotateX(-90deg);-ms-transform: perspective(600px) rotateX(-90deg);-o-transform: perspective(600px) rotateX(-90deg);transform: perspective(600px) rotateX(-90deg);-webkit-transform-origin: center top 0;-moz-transform-origin: center top 0;-ms-transform-origin: center top 0;-o-transform-origin: center top 0;transform-origin: center top 0;-webkit-transform-style: preserve-3d;-moz-transform-style: preserve-3d;-ms-transform-style: preserve-3d;-o-transform-style: preserve-3d;transform-style: preserve-3d;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperNe:hover .ContentNe {opacity: 1;visibility: visible;-webkit-transform: perspective(600px) rotateX(0deg);-moz-transform: perspective(600px) rotateX(0deg);-ms-transform: perspective(600px) rotateX(0deg);-o-transform: perspective(600px) rotateX(0deg);transform: perspective(600px) rotateX(0deg);}
.ContentWrapperNe .ContentNe .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperNe .ContentNe .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperNe .ContentNe .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperNa img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperNa:hover img {-webkit-transform: translateX(-20%);-moz-transform: translateX(-20%);-ms-transform: translateX(-20%);-o-transform: translateX(-20%);transform: translateX(-20%);}
.ContentWrapperNa .ContentNa {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 50%;height: 100%;display: block;top: 0;right: 0;-webkit-transform: perspective(600px) rotateY(-90deg);-moz-transform: perspective(600px) rotateY(-90deg);-ms-transform: perspective(600px) rotateY(-90deg);-o-transform: perspective(600px) rotateY(-90deg);transform: perspective(600px) rotateY(-90deg);-webkit-transform-origin: right center 0;-moz-transform-origin: right center 0;-ms-transform-origin: right center 0;-o-transform-origin: right center 0;transform-origin: right center 0;-webkit-transform-style: preserve-3d;-moz-transform-style: preserve-3d;-ms-transform-style: preserve-3d;-o-transform-style: preserve-3d;transform-style: preserve-3d;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperNa:hover .ContentNa {opacity: 1;visibility: visible;-webkit-transform: perspective(600px) rotateY(0deg);-moz-transform: perspective(600px) rotateY(0deg);-ms-transform: perspective(600px) rotateY(0deg);-o-transform: perspective(600px) rotateY(0deg);transform: perspective(600px) rotateY(0deg);}
.ContentWrapperNa .ContentNa .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperNa .ContentNa .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperNa .ContentNa .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperMg img {-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperMg:hover img {-webkit-transform: translateY(-20%);-moz-transform: translateY(-20%);-ms-transform: translateY(-20%);-o-transform: translateY(-20%);transform: translateY(-20%);}
.ContentWrapperMg .ContentMg {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 50%;display: block;bottom: 0;left: 0;-webkit-transform: perspective(600px) rotateX(90deg);-moz-transform: perspective(600px) rotateX(90deg);-ms-transform: perspective(600px) rotateX(90deg);-o-transform: perspective(600px) rotateX(90deg);transform: perspective(600px) rotateX(90deg);-webkit-transform-origin: center bottom 0;-moz-transform-origin: center bottom 0;-ms-transform-origin: center bottom 0;-o-transform-origin: center bottom 0;transform-origin: center bottom 0;-webkit-transform-style: preserve-3d;-moz-transform-style: preserve-3d;-ms-transform-style: preserve-3d;-o-transform-style: preserve-3d;transform-style: preserve-3d;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperMg:hover .ContentMg {opacity: 1;visibility: visible;-webkit-transform: perspective(600px) rotateY(0deg);-moz-transform: perspective(600px) rotateY(0deg);-ms-transform: perspective(600px) rotateY(0deg);-o-transform: perspective(600px) rotateY(0deg);transform: perspective(600px) rotateY(0deg);}
.ContentWrapperMg .ContentMg .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperMg .ContentMg .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperMg .ContentMg .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperAl .ContentAl {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: rotateY(0deg) scale(0.0);-moz-transform: rotateY(0deg) scale(0.0);-ms-transform: rotateY(0deg) scale(0.0);-o-transform: rotateY(0deg) scale(0.0);transform: rotateY(0deg) scale(0.0);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperAl:hover .ContentAl {opacity: 1;visibility: visible;-webkit-transform: rotateY(360deg) scale(.9);-moz-transform: rotateY(360deg) scale(.9);-ms-transform: rotateY(360deg) scale(.9);-o-transform: rotateY(360deg) scale(.9);transform: rotateY(360deg) scale(.9);}
.ContentWrapperAl .ContentAl .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperAl .ContentAl .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperAl .ContentAl .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperAl .ContentAl .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperAl .ContentAl .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperSi .ContentSi {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: rotateX(0deg) scale(0.0);-moz-transform: rotateX(0deg) scale(0.0);-ms-transform: rotateX(0deg) scale(0.0);-o-transform: rotateX(0deg) scale(0.0);transform: rotateX(0deg) scale(0.0);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperSi:hover .ContentSi {opacity: 1;visibility: visible;-webkit-transform: rotateX(360deg) scale(.9);-moz-transform: rotateX(360deg) scale(.9);-ms-transform: rotateX(360deg) scale(.9);-o-transform: rotateX(360deg) scale(.9);transform: rotateX(360deg) scale(.9);}
.ContentWrapperSi .ContentSi .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperSi .ContentSi .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperSi .ContentSi .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperSi .ContentSi .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperSi .ContentSi .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperP .ContentP {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: rotateY(0deg) scale(.9);-moz-transform: rotateY(0deg) scale(.9);-ms-transform: rotateY(0deg) scale(.9);-o-transform: rotateY(0deg) scale(.9);transform: rotateY(0deg) scale(.9);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperP:hover .ContentP {opacity: 1;visibility: visible;-webkit-transform: rotateY(360deg) scale(.9);-moz-transform: rotateY(360deg) scale(.9);-ms-transform: rotateY(360deg) scale(.9);-o-transform: rotateY(360deg) scale(.9);transform: rotateY(360deg) scale(.9);}
.ContentWrapperP .ContentP .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperP .ContentP .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperP .ContentP .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperP .ContentP .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperP .ContentP .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperS .ContentS {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: rotateX(0deg) scale(.9);-moz-transform: rotateX(0deg) scale(.9);-ms-transform: rotateX(0deg) scale(.9);-o-transform: rotateX(0deg) scale(.9);transform: rotateX(0deg) scale(.9);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperS:hover .ContentS {opacity: 1;visibility: visible;-webkit-transform: rotateX(360deg) scale(.9);-moz-transform: rotateX(360deg) scale(.9);-ms-transform: rotateX(360deg) scale(.9);-o-transform: rotateX(360deg) scale(.9);transform: rotateX(360deg) scale(.9);}
.ContentWrapperS .ContentS .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperS .ContentS .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperS .ContentS .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperS .ContentS .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperS .ContentS .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperCl {overflow: visible !important;}
.ContentWrapperCl img {position: relative;z-index: 455;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperCl:hover img {-webkit-transform: translateY(-40%);-moz-transform: translateY(-40%);-ms-transform: translateY(-40%);-o-transform: translateY(-40%);transform: translateY(-40%);}
.ContentWrapperCl .ContentCl {position: absolute;background: #ffffff;opacity: 1;visibility: hidden;width: 100%;height: 100%;display: block;bottom: 0;left: 0;z-index: 200;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperCl:hover .ContentCl {visibility: visible;}
.ContentWrapperCl .ContentCl .Content {position: absolute;top: 65%;display: block;width: 100%;}
.ContentWrapperCl .ContentCl .Content h2 {font:bold 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 2px;text-align: center;}
.ContentWrapperCl .ContentCl .Content .ReadMore {margin: 8px auto;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);display: block;width: 80px;}
.ContentWrapperCl .ContentCl .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperAr {overflow: visible !important;}
.ContentWrapperAr img {position: relative;z-index: 455;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperAr:hover img {-webkit-transform: translateY(40%);-moz-transform: translateY(40%);-ms-transform: translateY(40%);-o-transform: translateY(40%);transform: translateY(40%);}
.ContentWrapperAr .ContentAr {position: absolute;background: #ffffff;opacity: 1;visibility: hidden;width: 100%;height: 100%;display: block;bottom: 0;left: 0;z-index: 200;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperAr:hover .ContentAr {visibility: visible;}
.ContentWrapperAr .ContentAr .Content {position: absolute;top: 5%;display: block;width: 100%;}
.ContentWrapperAr .ContentAr .Content h2 {font:bold 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 2px;text-align: center;}
.ContentWrapperAr .ContentAr .Content .ReadMore {margin: 8px auto;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);display: block;width: 80px;}
.ContentWrapperAr .ContentAr .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperK {overflow: visible !important;}
.ContentWrapperK img {position: relative;z-index: 455;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperK:hover img {-webkit-transform: translateX(-20%);-moz-transform: translateX(-20%);-ms-transform: translateX(-20%);-o-transform: translateX(-20%);transform: translateX(-20%);}
.ContentWrapperK .ContentK {position: absolute;background: #ffffff;opacity: 1;visibility: hidden;width: 100%;height: 100%;display: block;bottom: 0;left: 0;z-index: 200;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperK:hover .ContentK {visibility: visible;}
.ContentWrapperK .ContentK .Content {display: block;width: 100%;position: relative;}
.ContentWrapperK .ContentK .Content ul {position: absolute;top: 0;right: 0;}
.ContentWrapperK .ContentK .Content ul li {margin: 14px 16px;}
.ContentWrapperK .ContentK .Content ul li a {font-size: 21px;color: #a9a9a9;}
.ContentWrapperCa {overflow: visible !important;}
.ContentWrapperCa img {position: relative;z-index: 455;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperCa:hover img {-webkit-transform: translateX(20%);-moz-transform: translateX(20%);-ms-transform: translateX(20%);-o-transform: translateX(20%);transform: translateX(20%);}
.ContentWrapperCa .ContentCa {position: absolute;background: #ffffff;opacity: 1;visibility: hidden;width: 100%;height: 100%;display: block;bottom: 0;left: 0;z-index: 200;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperCa:hover .ContentCa {visibility: visible;}
.ContentWrapperCa .ContentCa .Content {display: block;width: 100%;position: relative;}
.ContentWrapperCa .ContentCa .Content ul {position: absolute;top: 0;left: 0;}
.ContentWrapperCa .ContentCa .Content ul li {margin: 14px 16px;}
.ContentWrapperCa .ContentCa .Content ul li a {font-size: 21px;color: #a9a9a9;}
.ContentWrapperSc .ContentSc {position: absolute;background: #ffffff;visibility: hidden;width: 100%;height: 100%;display: block;top: -100%;left: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperSc:hover .ContentSc {visibility: visible;top: 0;}
.ContentWrapperSc .ContentSc .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperSc .ContentSc .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperSc .ContentSc .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperSc .ContentSc .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperSc .ContentSc .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperTi .ContentTi {position: absolute;background: #ffffff;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperTi:hover .ContentTi {visibility: visible;left: 0;}
.ContentWrapperTi .ContentTi .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperTi .ContentTi .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperTi .ContentTi .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperTi .ContentTi .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperTi .ContentTi .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperV .ContentV {position: absolute;background: #ffffff;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;right: -100%;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperV:hover .ContentV {visibility: visible;right: 0;}
.ContentWrapperV .ContentV .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperV .ContentV .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperV .ContentV .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperV .ContentV .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperV .ContentV .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperCr .ContentCr {position: absolute;background: #ffffff;visibility: hidden;width: 100%;height: 0;display: block;bottom: -100%;left: 0;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperCr:hover .ContentCr {visibility: visible;height: 100%;bottom: 0;}
.ContentWrapperCr .ContentCr .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperCr .ContentCr .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperCr .ContentCr .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperCr .ContentCr .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperCr .ContentCr .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperMn .ContentMn {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform-origin: top left;-moz-transform-origin: top left;-ms-transform-origin: top left;-o-transform-origin: top left;transform-origin: top left;-webkit-transform-style: preserve-3D;-moz-transform-style: preserve-3D;-ms-transform-style: preserve-3D;-o-transform-style: preserve-3D;transform-style: preserve-3D;-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);transform: rotate(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperMn:hover .ContentMn {opacity: 1;visibility: visible;-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);}
.ContentWrapperMn .ContentMn .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperMn .ContentMn .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperMn .ContentMn .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperMn .ContentMn .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperMn .ContentMn .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperFe .ContentFe {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;right: 0;-webkit-transform-origin: top right;-moz-transform-origin: top right;-ms-transform-origin: top right;-o-transform-origin: top right;transform-origin: top right;-webkit-transform-style: preserve-3D;-moz-transform-style: preserve-3D;-ms-transform-style: preserve-3D;-o-transform-style: preserve-3D;transform-style: preserve-3D;-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);transform: rotate(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperFe:hover .ContentFe {opacity: 1;visibility: visible;-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);}
.ContentWrapperFe .ContentFe .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperFe .ContentFe .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperFe .ContentFe .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperFe .ContentFe .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperFe .ContentFe .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperCo .ContentCo {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform-origin: bottom left;-moz-transform-origin: bottom left;-ms-transform-origin: bottom left;-o-transform-origin: bottom left;transform-origin: bottom left;-webkit-transform-style: preserve-3D;-moz-transform-style: preserve-3D;-ms-transform-style: preserve-3D;-o-transform-style: preserve-3D;transform-style: preserve-3D;-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);transform: rotate(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperCo:hover .ContentCo {opacity: 1;visibility: visible;-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);}
.ContentWrapperCo .ContentCo .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperCo .ContentCo .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperCo .ContentCo .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperCo .ContentCo .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperCo .ContentCo .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperNi .ContentNi {position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;right: 0;-webkit-transform-origin: bottom right;-moz-transform-origin: bottom right;-ms-transform-origin: bottom right;-o-transform-origin: bottom right;transform-origin: bottom right;-webkit-transform-style: preserve-3D;-moz-transform-style: preserve-3D;-ms-transform-style: preserve-3D;-o-transform-style: preserve-3D;transform-style: preserve-3D;-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);transform: rotate(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperNi:hover .ContentNi {opacity: 1;visibility: visible;-webkit-transform: rotate(0deg);-moz-transform: rotate(0deg);-ms-transform: rotate(0deg);-o-transform: rotate(0deg);transform: rotate(0deg);}
.ContentWrapperNi .ContentNi .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperNi .ContentNi .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperNi .ContentNi .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperNi .ContentNi .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperNi .ContentNi .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperCu img {-webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;-ms-backface-visibility: hidden;-o-backface-visibility: hidden;backface-visibility: hidden;-webkit-transform: rotateY(0deg);-moz-transform: rotateY(0deg);-ms-transform: rotateY(0deg);-o-transform: rotateY(0deg);transform: rotateY(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperCu:hover img {-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-ms-transform: rotateY(180deg);-o-transform: rotateY(180deg);transform: rotateY(180deg);}
.ContentWrapperCu .ContentCu {-webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;-ms-backface-visibility: hidden;-o-backface-visibility: hidden;backface-visibility: hidden;position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-ms-transform: rotateY(180deg);-o-transform: rotateY(180deg);transform: rotateY(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperCu:hover .ContentCu {opacity: 1;visibility: visible;-webkit-transform: rotateY(0deg);-moz-transform: rotateY(0deg);-ms-transform: rotateY(0deg);-o-transform: rotateY(0deg);transform: rotateY(0deg);}
.ContentWrapperCu .ContentCu .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperCu .ContentCu .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperCu .ContentCu .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperCu .ContentCu .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperCu .ContentCu .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
.ContentWrapperZn img {-webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;-ms-backface-visibility: hidden;-o-backface-visibility: hidden;backface-visibility: hidden;-webkit-transform: rotateX(0deg);-moz-transform: rotateX(0deg);-ms-transform: rotateX(0deg);-o-transform: rotateX(0deg);transform: rotateX(0deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperZn:hover img {-webkit-transform: rotateX(180deg);-moz-transform: rotateX(180deg);-ms-transform: rotateX(180deg);-o-transform: rotateX(180deg);transform: rotateX(180deg);}
.ContentWrapperZn .ContentZn {-webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;-ms-backface-visibility: hidden;-o-backface-visibility: hidden;backface-visibility: hidden;position: absolute;background: #ffffff;opacity: 0;visibility: hidden;width: 100%;height: 100%;display: block;top: 0;left: 0;-webkit-transform: rotateX(180deg);-moz-transform: rotateX(180deg);-ms-transform: rotateX(180deg);-o-transform: rotateX(180deg);transform: rotateX(180deg);-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ContentWrapperZn:hover .ContentZn {opacity: 1;visibility: visible;-webkit-transform: rotateX(0deg);-moz-transform: rotateX(0deg);-ms-transform: rotateX(0deg);-o-transform: rotateX(0deg);transform: rotateX(0deg);}
.ContentWrapperZn .ContentZn .Content {position: absolute;top: 10%;padding: 0 10px;}
.ContentWrapperZn .ContentZn .Content h2 {font: 16px'Source Sans Pro', Arial, sans-serif;color: #8CA757;padding: 0 0 6px;}
.ContentWrapperZn .ContentZn .Content p {font: normal 12px'Source Sans Pro';color: #666666;}
.ContentWrapperZn .ContentZn .Content .ReadMore {float: right;margin: 16px 0 0;background: #D1CDC3;background: -moz-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D1CDC3), color-stop(100%, #C9C5BA));background: -webkit-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -o-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: -ms-linear-gradient(top, #D1CDC3 0%, #C9C5BA 100%);background: linear-gradient(to bottom, #D1CDC3 0%, #C9C5BA 100%);filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#D1CDC3', endColorstr='#C9C5BA', GradientType=0);-webkit-border-radius: 2px 2px 2px 2px;-moz-border-radius: 2px 2px 2px 2px;-ms-border-radius: 2px 2px 2px 2px;-o-border-radius: 2px 2px 2px 2px;border-radius: 2px 2px 2px 2px;-webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-moz-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-ms-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);-o-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.08) inset, 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0);}
.ContentWrapperZn .ContentZn .Content .ReadMore a {color: #757167;padding: 6px 16px;display: block;font: normal 12px'Source Sans Pro';}
/*  =============================================
Ribbons
============================================= */
.ImageWrapper .RibbonCTL .Triangle:after {border-right: 35px solid rgba(0, 0, 0, 0);border-top: 35px solid #FFFFFF;content:" ";display: block;height: 0;position: absolute;width: 0;top: 0;left: 0;z-index: 99;}
.ImageWrapper .RibbonCTL .Sign {top: 2px;left: 2px;position: absolute;z-index: 999;}
.ImageWrapper .RibbonCTL .Sign a {color: #666666;}
.ImageWrapper .RibbonCTL {opacity: 0;visibility: hidden;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .RibbonCTL {opacity: 1;visibility: visible;}
.ImageWrapper .RibbonCTR .Triangle:after {border-left: 35px solid rgba(0, 0, 0, 0);border-top: 35px solid #FFFFFF;content:" ";display: block;height: 0;position: absolute;width: 0;top: 0;right: 0;z-index: 99;}
.ImageWrapper .RibbonCTR .Sign {top: 2px;right: 2px;position: absolute;z-index: 999;}
.ImageWrapper .RibbonCTR .Sign a {color: #666666;}
.ImageWrapper .RibbonCTR {opacity: 0;visibility: hidden;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .RibbonCTR {opacity: 1;visibility: visible;}
.ImageWrapper .RibbonCBL .Triangle:after {border-right: 35px solid rgba(0, 0, 0, 0);border-bottom: 35px solid #FFFFFF;content:" ";display: block;height: 0;position: absolute;width: 0;bottom: 0;left: 0;z-index: 99;}
.ImageWrapper .RibbonCBL .Sign {bottom: 1px;left: 1px;position: absolute;z-index: 999;}
.ImageWrapper .RibbonCBL .Sign a {color: #666666;}
.ImageWrapper .RibbonCBL {opacity: 0;visibility: hidden;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .RibbonCBL {opacity: 1;visibility: visible;}
.ImageWrapper .RibbonCBR .Triangle:after {border-left: 35px solid rgba(0, 0, 0, 0);border-bottom: 35px solid #FFFFFF;content:" ";display: block;height: 0;position: absolute;width: 0;bottom: 0;right: 0;z-index: 99;}
.ImageWrapper .RibbonCBR .Sign {bottom: 1px;right: 1px;position: absolute;z-index: 999;}
.ImageWrapper .RibbonCBR .Sign a {color: #666666;}
.ImageWrapper .RibbonCBR {opacity: 0;visibility: hidden;-webkit-transition: all 0.3s ease 0s;-moz-transition: all 0.3s ease 0s;-ms-transition: all 0.3s ease 0s;-o-transition: all 0.3s ease 0s;transition: all 0.3s ease 0s;}
.ImageWrapper:hover .RibbonCBR {opacity: 1;visibility: visible;}
/*  =============================================
Transparent Background
============================================= */
.TBlack {background: none repeat scroll 0 0 rgba(0,0,0,.8) !important;}
.TWhite {background: none repeat scroll 0 0 rgba(255,255,255,.8) !important;}